import { toRefs } from "@vueuse/core";

export type UseUIState = {
  overlay: boolean;
  navigation: boolean;
  mobileSearch: boolean;
  userMenu: boolean;
  adviceMenu: boolean;
  searchResults: boolean;
  branchTray: boolean;
  branchTrayTitle: "collection" | "delivery" | null;
  nearestBranchTray: boolean;
  sustainabilityTray: boolean;
  specificationsTray: boolean;
  dropshipTray: boolean;
  tallyTray: boolean;
  basketTray: boolean;
  addressTray: boolean;
  editUserTray: boolean;
  modal: boolean;
  autoBranchSelect: boolean;
  isVatIncluded: boolean;
  categoryFiltersTray: boolean;
  changeEmailTray: boolean;
  changePasswordTray: boolean;
  personalDetailsTray: boolean;
  branchPostcode: string | null;
  deliveryMethod: string;
  filterInvoicesTray: boolean;
  filterOrdersTray: boolean;
  basketTrayFirstOpen: boolean;
  loginTray: boolean;
  branchSwitchTray: boolean;
  mergeWarning: boolean;
  mergeCancelBranch: number | null;
};

export type UseUI = {
  setOverlay: (overlayState: UseUIState["overlay"]) => void;
  setNavigation: () => void;
  onOverlayClick: () => void;
  setUserMenu: (setState?: boolean) => void;
  setMobileSearch: (setState: boolean) => void;
  setAdviceMenu: (setState?: boolean) => void;
  setSearchResults: (setState: boolean) => void;
  setBranchTray: (postcode?: string, title?: "collection" | "delivery") => void;
  setSustainabilityTray: () => void;
  setSpecificationsTray: () => void;
  setDropshipTray: () => void;
  setTallyTray: () => void;
  setBasketTray: (firstOpen?: boolean) => void;
  setAddressTray: () => void;
  setModal: () => void;
  setEditUserTray: () => void;
  setNearestBranchTray: () => void;
  setLoginTray: () => void;
  setAutoBranchSelect: (setState: boolean) => void;
  toggleIsVatIncluded: () => void;
  setCategoryFilters: () => void;
  setChangeEmailTray: (setState?: boolean) => void;
  setChangePasswordTray: (setState?: boolean) => void;
  setPersonalDetailsTray: (setState?: boolean) => void;
  changeDeliveryMethod: (newValue: string) => void;
  toggleFilterInvoicesTray: () => void;
  setBranchSwitchTray: (setState: boolean) => void;
  setMergeWarning: (setState: boolean, mergeCancelBranch: number) => void;
  toggleFilterOrdersTray: () => void;
} & {
  [Keys in keyof UseUIState]: Ref<UseUIState[Keys]>;
};

const useUIState = (): UseUI => {
  const state = useState<UseUIState>(`useUIState`, () => ({
    overlay: false,
    navigation: false,
    mobileSearch: false,
    userMenu: false,
    adviceMenu: false,
    searchResults: false,
    branchTray: false,
    branchTrayTitle: null,
    nearestBranchTray: false,
    sustainabilityTray: false,
    specificationsTray: false,
    dropshipTray: false,
    tallyTray: false,
    basketTray: false,
    addressTray: false,
    modal: false,
    editUserTray: false,
    autoBranchSelect: false,
    categoryFiltersTray: false,
    branchPostcode: null,
    isVatIncluded: process.client && localStorage.isVatIncluded ? localStorage.isVatIncluded === "true" : true,
    changeEmailTray: false,
    changePasswordTray: false,
    personalDetailsTray: false,
    deliveryMethod: "delivery",
    filterInvoicesTray: false,
    filterOrdersTray: false,
    basketTrayFirstOpen: false,
    loginTray: false,
    branchSwitchTray: false,
    mergeWarning: false,
    mergeCancelBranch: null,
  }));

  const setOverlay = (overlayState: UseUIState["overlay"]) => {
    if (state.value.autoBranchSelect) overlayState = true;

    state.value.overlay = overlayState;
  };

  const setCategoryFilters = () => {
    state.value.categoryFiltersTray = !state.value.categoryFiltersTray;
    setOverlay(state.value.categoryFiltersTray);
  };

  const setNavigation = () => {
    state.value.navigation = !state.value.navigation;
    setOverlay(state.value.navigation);
  };

  const isAnyTrayOpen = () => {
    return (
      state.value.navigation ||
      state.value.mobileSearch ||
      state.value.searchResults ||
      state.value.branchTray ||
      state.value.specificationsTray ||
      state.value.sustainabilityTray ||
      state.value.dropshipTray ||
      state.value.tallyTray ||
      state.value.categoryFiltersTray ||
      state.value.basketTray ||
      state.value.addressTray ||
      state.value.modal ||
      state.value.editUserTray ||
      state.value.changeEmailTray ||
      state.value.changePasswordTray ||
      state.value.personalDetailsTray ||
      state.value.nearestBranchTray ||
      state.value.filterInvoicesTray ||
      state.value.filterOrdersTray ||
      state.value.loginTray ||
      state.value.branchSwitchTray ||
      state.value.mergeWarning
    );
  };

  const closeAllTrays = () => {
    state.value.navigation = false;
    state.value.mobileSearch = false;
    state.value.searchResults = false;
    state.value.branchTray = false;
    state.value.specificationsTray = false;
    state.value.sustainabilityTray = false;
    state.value.dropshipTray = false;
    state.value.tallyTray = false;
    state.value.categoryFiltersTray = false;
    state.value.basketTray = false;
    state.value.addressTray = false;
    state.value.modal = false;
    state.value.editUserTray = false;
    state.value.changeEmailTray = false;
    state.value.changePasswordTray = false;
    state.value.personalDetailsTray = false;
    state.value.nearestBranchTray = false;
    state.value.filterInvoicesTray = false;
    state.value.filterOrdersTray = false;
    state.value.loginTray = false;
    state.value.branchSwitchTray = false;
    state.value.mergeWarning = false;
  };

  const setAutoBranchSelect = (setState: boolean) => {
    state.value.autoBranchSelect = setState;
    setOverlay(state.value.autoBranchSelect);
  };

  const onOverlayClick = () => {
    if (isAnyTrayOpen()) {
      closeAllTrays();
      state.value.overlay = state.value.autoBranchSelect;
    } else if (state.value.autoBranchSelect) {
      setAutoBranchSelect(false);
    } else {
      setOverlay(false);
    }
  };

  const setMobileSearch = (setState: boolean) => {
    state.value.mobileSearch = setState;
    setOverlay(state.value.mobileSearch);
  };

  const setBranchSwitchTray = (setBranch: boolean) => {
    state.value.branchSwitchTray = setBranch;
    setOverlay(state.value.branchSwitchTray);
  };

  const setMergeWarning = (setMergeWarning: boolean, mergeCancelBranch: number) => {
    state.value.mergeWarning = setMergeWarning;
    state.value.mergeCancelBranch = mergeCancelBranch;
    setOverlay(state.value.mergeWarning);
  };

  const setSearchResults = (setState: boolean) => {
    state.value.searchResults = setState;
    if (!state.value.mobileSearch) setOverlay(false);
  };

  const setUserMenu = (setState?: boolean) => {
    if (setState !== undefined) {
      state.value.userMenu = setState;
      return;
    }

    state.value.userMenu = !state.value.userMenu;

    if (state.value.adviceMenu) state.value.adviceMenu = false;
  };

  const setAdviceMenu = (setState?: boolean) => {
    if (setState !== undefined) {
      state.value.adviceMenu = setState;
      return;
    }

    state.value.adviceMenu = !state.value.adviceMenu;

    if (state.value.userMenu) state.value.userMenu = false;
  };

  const setBranchTray = (postcode?: string | null, title?: "collection" | "delivery") => {
    state.value.branchTrayTitle = title ?? null;
    state.value.branchPostcode = postcode ?? null;
    state.value.branchTray = !state.value.branchTray;
    setOverlay(state.value.branchTray);
  };

  const setSustainabilityTray = () => {
    state.value.sustainabilityTray = !state.value.sustainabilityTray;
    setOverlay(state.value.sustainabilityTray);
  };

  const setSpecificationsTray = () => {
    state.value.specificationsTray = !state.value.specificationsTray;
    setOverlay(state.value.specificationsTray);
  };

  const setDropshipTray = () => {
    state.value.dropshipTray = !state.value.dropshipTray;
    setOverlay(state.value.dropshipTray);
  };

  const setTallyTray = () => {
    state.value.tallyTray = !state.value.tallyTray;
    setOverlay(state.value.tallyTray);
  };

  const setBasketTray = (firstOpen?: boolean) => {
    state.value.basketTrayFirstOpen = firstOpen || false;
    state.value.basketTray = !state.value.basketTray;
    setOverlay(state.value.basketTray);
  };

  const setAddressTray = () => {
    state.value.addressTray = !state.value.addressTray;
    setOverlay(state.value.addressTray);
  };

  const setEditUserTray = () => {
    state.value.editUserTray = !state.value.editUserTray;
    setOverlay(state.value.editUserTray);
  };

  const setModal = () => {
    state.value.modal = !state.value.modal;
    setOverlay(state.value.modal);
  };

  const toggleIsVatIncluded = () => {
    state.value.isVatIncluded = !state.value.isVatIncluded;
    localStorage.isVatIncluded = state.value.isVatIncluded.toString();
  };

  const setChangeEmailTray = (newValue?: UseUIState["changeEmailTray"]) => {
    state.value.changeEmailTray = newValue === undefined ? !state.value.changeEmailTray : newValue;

    setOverlay(state.value.changeEmailTray);
  };

  const setChangePasswordTray = (newValue?: UseUIState["changePasswordTray"]) => {
    state.value.changePasswordTray = newValue === undefined ? !state.value.changePasswordTray : newValue;
    setOverlay(state.value.changePasswordTray);
  };

  const setPersonalDetailsTray = (newValue?: UseUIState["personalDetailsTray"]) => {
    state.value.personalDetailsTray = newValue === undefined ? !state.value.personalDetailsTray : newValue;
    setOverlay(state.value.personalDetailsTray);
  };

  const setNearestBranchTray = () => {
    state.value.nearestBranchTray = !state.value.nearestBranchTray;
    setOverlay(state.value.nearestBranchTray);
  };

  const changeDeliveryMethod = (newValue: string) => (state.value.deliveryMethod = newValue);

  const toggleFilterInvoicesTray = () => {
    state.value.filterInvoicesTray = !state.value.filterInvoicesTray;
    setOverlay(state.value.filterInvoicesTray);
  };

  const toggleFilterOrdersTray = () => {
    state.value.filterOrdersTray = !state.value.filterOrdersTray;
    setOverlay(state.value.filterOrdersTray);
  };

  const setLoginTray = () => {
    state.value.loginTray = !state.value.loginTray;
    setOverlay(state.value.loginTray);
  };

  return {
    setOverlay,
    setNavigation,
    onOverlayClick,
    setMobileSearch,
    setUserMenu,
    setAdviceMenu,
    setSearchResults,
    setBranchTray,
    setSustainabilityTray,
    setSpecificationsTray,
    setDropshipTray,
    setTallyTray,
    setBasketTray,
    setAddressTray,
    setEditUserTray,
    setModal,
    setAutoBranchSelect,
    setNearestBranchTray,
    toggleIsVatIncluded,
    setCategoryFilters,
    setChangeEmailTray,
    setChangePasswordTray,
    setPersonalDetailsTray,
    changeDeliveryMethod,
    toggleFilterInvoicesTray,
    toggleFilterOrdersTray,
    setLoginTray,
    setBranchSwitchTray,
    setMergeWarning,
    ...toRefs(state.value),
  };
};

export default useUIState;
